<template>
  <div :class="isMobile?'mobile-main':'main'">
    <div class="stored-header">
      <div class="title">专属VIP充值官网</div>
      <div class="title-right" @click="goOrder">
        订单<br/>查看
      </div>
    </div>
    <div class="stored-content">
      <div class="stored_content_select">
        <div class="stored_content_select_item">
          <div class="stored-title">区服</div>
          <div class="stored-select">
            <label @click="showServer">{{ selectServer }}</label>
            <ul v-if="isShowServer">
              <li v-for="item in serverRoleData" :key="item.server_id" @click="serverChange(item.server_id)" >
                {{ (item.server_id ? item.server_name + '【S'+item.server_id+'】': item.server_name) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="stored_content_select_item">
          <div class="stored-title">角色</div>
          <div class="stored-select">
            <label @click="showRole">{{ selectRole }}</label>
            <ul v-if="isShowRole">
              <li v-for="item in rolesData" :key="item.role_id" @click="roleChange(item.role_id)">{{ item.role_name }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="stored_content_role">
        <div class="role-name">账号：{{ this.gameUserInfo.username }}</div>
        <div class="role-but" @click="goOut">登出</div>
      </div>
      <div v-if="this.gameUserInfo.user_id ===1045793 || this.gameUserInfo.user_id===1002649 || this.gameUserInfo.user_id===1727">
        <div class="stored_content_itmes" v-if="isShowItem">
          <div class="stored_content_itme" v-for="item in itemsTest" :key="item.item_num" :class="{ active: item.item_num=== item_num }" @click="selectItem(item)">
            <div class="itme_img"><img :src="item.img" /></div>
            <div class="itme_name">{{ item.item_name }}</div>
            <div class="itme_delivery">赠送{{ item.item_discount }}仙玉</div>
            <div class="item_price">￥{{ item.item_price }}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="stored_content_itmes" v-if="isShowItem">
          <div class="stored_content_itme" v-for="item in items" :key="item.item_num" :class="{ active: item.item_num=== item_num }" @click="selectItem(item)">
            <div class="itme_img"><img :src="item.img" /></div>
            <div class="itme_name">{{ item.item_name }}</div>
            <div class="itme_delivery">赠送{{ item.item_discount }}仙玉</div>
            <div class="item_price">￥{{ item.item_price }}</div>
          </div>
        </div>
      </div>
      <div class="stored_content_pay" v-if="isShowPay">
        <div class="desc_title">选择支付方式：</div>
        <div class="pay_list" v-for="(item,index) in storedData" :key="index" @click="payTypeChannge(item)" :class="{ active: item.name=== pay_type }">
          <a href="javascript:void(0)"><img :src="item.image" :alt="item.name"></a>
        </div>
      </div>
      <div class="stored_content_desc">
        <div class="desc_title">说明 :</div>
        <div class="desc_content">
          <p>1、请确认角色名与您要充值的角色一致</p>
          <p>2、若充值存在任何疑问，请您先联系专属客服哟~</p>
        </div>
      </div>
    </div>
    <div class="stored-footer">
      <div class="left" @click="createSuperLabel('qq',qqUrl)">
        <img :src="staticUrl + 'kefu.png'"/>
        联系客服
      </div>
      <div class="right" @click="onRecharge" >立即支付</div>
    </div>
  </div>
</template>

<script>
import {  mapGetters, mapActions } from 'vuex'
import { now } from 'moment';

export default {
  name: 'Recharge',
  computed: {
    ...mapGetters(['isMobile', 'wxOpenid', 'wxExpiresIn', 'isBrowser', 'staticUrl', 'deviceIdSting','qqUrl', 'storedData', 'items', 'itemsTest', 'gameUserInfo', 'serverRoleData'])
  },
  data () {
    return {
      app_id: '',
      serverId: '',
      serverName: '',
      selectServer: '请选择您所在的区服',
      roleId: '',
      roleName: '',
      selectRole: '请选择您的充值角色',
      pay_type: '', // alipay
      item_id: '',
      item_name: '',
      item_price: '',
      item_num: 0,
      // item_discount: '',
      isShowItem: false,
      isShowPay: false,
      rolesData: [],
      isShowServer: false,
      isShowRole: false
    }
  },
  created () {
    if (this.gameUserInfo.token === '') {
      this.$router.push({ name: 'home', query: {} })
    } else {
      if (this.isBrowser === 'weixin' && (this.wxOpenid === '' || this.wxExpiresIn < Date.now())) {
        this.goWxpayAuth()
      } else {
        this.GetBambooGameRoles().then((res) => {})
      }
    }
  },
  methods: {
    ...mapActions(['UserOut','GetZhuziOrderSign','ZhuziOrderCreateAlipay','ZhuziOrderCreateWxpay', 'BambooOrderCreate', 'GetBambooGameRoles', 'GetRechargePreOrder','ZhuziOrderCreateJsapiWxpay','ZhuziWxpayAuth']),
    goOut() {
      this.UserOut().then((res) => {
        this.showSuccess('退出成功')
        window.location.href = ('/')
      })
    },
    goOrder () {
      this.$router.push({ name: 'order', query: {} })
    },
    createSuperLabel (id, url) {
      let a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('target', '_blank');
      a.setAttribute('id', id);
      if (!document.getElementById(id)) {
        document.body.appendChild(a);
      }
      a.click();
    },
    showServer() {
      if (!this.isShowServer) {
        this.isShowServer = true
      } else {
        this.isShowServer = false
      }
    },
    serverChange (id){
      this.roleId = ''
      this.roleName = ''
      this.isShowPay = false
      var rolse = []
      this.serverRoleData.forEach(element => {
        if (element.server_id === id) {
          this.serverId = id
          this.serverName = element.server_name
          this.selectServer = element.server_name + '【S'+element.server_id+'】'
          rolse.push({ role_id: element.role_id, role_name: element.role_name })
        }
      })
      this.rolesData = rolse
      this.isShowServer = false
    },
    showRole() {
      if (!this.isShowRole) {
        this.isShowRole= true
      } else {
        this.isShowRole = false
      }
    },
    roleChange (id) {
      this.rolesData.forEach(element => {
        if(element.role_id === id) {
          this.roleName = element.role_name
          this.selectRole = element.role_name
          this.roleId = id
        }
      })
      if (this.roleName) {
        this.isShowItem = true
      }else {
        this.isShowItem = false
        this.pay_type = ''
      }
      this.isShowRole = false
    },
    payTypeChannge (e) {
      this.pay_type = e.name
    },
    selectItem (e) {
      this.item_id = e.item_id
      this.item_name = e.item_name
      this.item_price = e.item_price
      // this.item_discount = e.item_discount
      this.item_num = e.item_num
      this.isShowPay = true
    },
    onRecharge () {
      if (!this.serverId) {
        this.showError('请选择区服！')
        return
      }
      if (!this.roleId) {
        this.showError('请选择角色！')
        return
      }
      if (!this.item_id) {
        this.showError('请选择充值金额！')
        return
      }
      if (!this.pay_type) {
        this.showError('请选择支付方式！')
        return
      }
      var params = {
        bamboo: true,
        device_type: 'iOS',
        user_id: this.gameUserInfo.user_id,
        open_id: this.gameUserInfo.open_id,
        server_id: this.serverId,
        server_name: this.serverName,
        role_id: this.roleId,
        role_name: this.roleName,
        item_id: this.item_id,
        item_name: this.item_name,
        item_desc: this.item_id + this.item_name,
        num: this.item_num,
        extra: this.item_id + this.item_name,
        money: (this.item_price*100),
        coin: (this.item_price*100),
        token: this.gameUserInfo.token,
        notify_url: '', // 测试时用https://mnjycntest.qingniaogame.com/charge/bamboo/pay，正式上线此参数去掉
      }

      this.GetRechargePreOrder(params).then(res => {
        if (res.code === 1) {
          this.showError(res.msg)
          return
        } else {
          params.app_id = res.app_id
          params.cp_order_id = res.cp_order_id
          params.currency = res.currency
          params.extra = res.extra
          params.item_id = res.item_id
          params.money = res.money
          params.num = res.num
          params.role_id = res.role_id
          params.server_id = res.server_id
          params.sign = res.sign
          this.bambooOrderCreate(params)
        }
      }).catch(() => {
        this.showError('订单已经提交失败')
      })
    },
    bambooOrderCreate (params) {
      this.BambooOrderCreate(params).then(res => {
        if (res.code === 1) {
          this.showError(res.msg)
          return
        } else {
          var zhuziParams = {
            zhuzi: true,
            channel: 'zhuzi',
            device_type: 'iOS',
            server_id: res.server_id,
            server_name: this.serverName,
            role_id: res.role_id,
            role_name: this.roleName,
            item_id: res.item_id,
            item: res.item,
            num: res.num,
            extra: params.extra,
            pay_channel: this.pay_type,
            money: res.money,
            cp_order_id: res.cp_order_id,
            custom: res.custom,
            notify_url: res.notify_url,
            user_id: this.gameUserInfo.channel_user_id,
            sign: res.sign,
            ts: res.ts,
            callback_url: 'https://pay.17youshan.com/orderReturn',
          }
          // console.log(zhuziParams)
          this.zhuziCreateOrder(zhuziParams)
        }
      }).catch(() => {
        this.showError('充值订单已经提交失败')
      })
    },
    zhuziCreateOrder (params) {
      let that = this
      if (that.pay_type === 'alipay') {
        that.ZhuziOrderCreateAlipay(params).then((res) => {
          that.isShowPay = false
          that.item_id = ''
          that.pay_type = ''
          that.item_num = 0
          that.showSuccess('支付宝充值订单已经提交成功')
          if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
            location.href = res.rsp
          } else {
            window.open(res.rsp, '_blank');
          }
        }).catch(() => {
          that.showError('支付宝充值订单已经提交失败')
        })
      } else {
        if(this.isBrowser === 'weixin'){
          params.wx_openid = this.wxOpenid
          const wxInfo = {
            appId: "",     //公众号ID，由商户传入
            timeStamp: "",         //时间戳，自1970年以来的秒数
            nonceStr: "", //随机串
            package: "",
            signType: "",         //微信签名方式：
            paySign: "" //微信签名
          }
          that.ZhuziOrderCreateJsapiWxpay(params).then((res) => {
            if (res.code === 1) {
              this.showError(res.msg)
              return
            } else {
              that.isShowPay = false
              that.item_id = ''
              that.pay_type = ''
              that.item_num = 0
              wxInfo.appId = res.rsp.appId
              wxInfo.package = res.rsp.package
              wxInfo.nonceStr = res.rsp.nonceStr
              wxInfo.signType = res.rsp.signType
              wxInfo.paySign = res.rsp.paySign
              wxInfo.timeStamp = res.rsp.timeStamp

              if (typeof WeixinJSBridge == "undefined"){
                if( document.addEventListener ){
                  document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(wxInfo), false);
                }else if (document.attachEvent){
                  document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(wxInfo));
                  document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(wxInfo));
                }
              }else{
                this.onBridgeReady(wxInfo)
              }
              // that.showSuccess('微信充值订单已经提交成功')
            }
          }).catch(() => {
            that.showError('微信充值订单已经提交失败')
          })
        } else {
          that.ZhuziOrderCreateWxpay(params).then((res) => {
            that.isShowPay = false
            that.item_id = ''
            that.pay_type = ''
            that.item_num = 0
            that.showSuccess('微信充值订单已经提交成功')
            if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
              location.href = res.rsp
            } else {
              window.open(res.rsp, '_blank');
            }
          }).catch(() => {
            that.showError('微信充值订单已经提交失败')
          })
        }
      }
    },
    goWxpayAuth () {
      const code = this.getUrlParam('code')
      if (code == null || code == '') {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcb3c73e92a22121b&redirect_uri=https%3A%2F%2Fpay.17youshan.com%2Fstored%2F&response_type=code&scope=snsapi_base&state=#wechat_redirect'
      } else {
        let params = {
          zhuzi: true,
          code: code
        }
        this.ZhuziWxpayAuth(params).then((res) => {
          if (res.code === 1) {
            this.showError(res.msg)
            return
          } else {
            this.GetBambooGameRoles().then((res) => {})
          }
        })
      }
    },
    // 编码函数
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)") //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg) //匹配目标参数
      if (r != null) {
        return unescape(r[2])
      } else {
        return null; //返回参数值
      }
    },
    onBridgeReady (wxInfo) {
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": wxInfo.appId,     //公众号ID，由商户传入
        "timeStamp": wxInfo.timeStamp,         //时间戳，自1970年以来的秒数
        "nonceStr": wxInfo.nonceStr, //随机串
        "package": wxInfo.package,
        "signType": wxInfo.signType,         //微信签名方式：
        "paySign": wxInfo.paySign //微信签名
      },
      function(res){
        if(res.err_msg == "get_brand_wcpay_request:ok" ){
          this.showSuccess('微信充值订单已经提交成功')
        }
      });
    }
  }
};
</script>

<style lang="scss">

</style>
