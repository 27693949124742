<template>
  <div class="dialog_wrap" @click="closeDialog">
    <div class="dialog_wrap_bg" @click.stop="noTouch">
    <div class="dialog_wrap_close" @click="closeDialog"></div>
      <div class="dialog_wrap_content">
        <div class="content_title">{{ title }}扫码</div>
        <div class="content_img" v-if="qrCodeImg">
          <img :src="qrCodeImg" class="code-img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QRCode from 'qrcode'
export default {
  name: 'ImgCode',
  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    codeUrl: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
     qrCodeImg: ''
    }
  },
  computed: {
    ...mapGetters(['isMobile','staticUrl']),
  },
  mounted () {
    document.getElementsByTagName('body')[0].classList.add('noscroll');
  },
  beforeDestroy () {
    document.getElementsByTagName('body')[0].classList.remove('noscroll');
  },
  created () {
    if (this.codeUrl) {
      this.getCode()
    }
  },
  methods: {
    noTouch () {

    },
    closeDialog () {
      this.$emit('onClose', true)
    },
    getCode () {
      QRCode.toDataURL(this.codeUrl).then(url => {
        this.qrCodeImg = url
      })
    }
  }
}
</script>

<style lang="scss">

</style>
