<template>
  <div class="mobile-main">
    <user-login/>
  </div>
</template>

<script>
import { UserLogin } from '@/components'
export default {
  name: 'mobileLogin',
  components: {
    UserLogin,
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {}
}
</script>

<style lang="scss">
  @import "@/assets/mobile/index.scss";
</style>
