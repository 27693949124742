import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/filter' // global filter
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueClipboard from 'vue-clipboard2'
import Toast from '@/components/toast/index.js'

Vue.use(VueAwesomeSwiper)
Vue.use(VueClipboard)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

Vue.prototype.showSuccess = function (title) {
  // Message.success(title)
  Toast({message: title, type: 'success'})
}

Vue.prototype.showError = function (title) {
  // Message.warning(title)
  Toast({message: title, type: 'warning'})
}
