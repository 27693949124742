const dataForm = {
  state: {
    job_type: [
      { id: 1, name: '内推' },
      { id: 2, name: '外招' },
      { id: 3, name: '校招' }
    ],
    job_location: [
      { id: '上海', name: '上海' },
      { id: '杭州', name: '杭州' },
      { id: '西安', name: '西安' },
      { id: '广州', name: '广州' },
      { id: '北京', name: '北京' }
    ],
    job_label: [
      { id: 1, name: '紧急' },
      { id: 2, name: '最新' },
      { id: 3, name: '热门' }
    ]
  }
}

export default dataForm
