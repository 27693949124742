<template>
  <div :class="isMobile?'mobile-main':'main'">
    <div class="order-header">
      <div class="title">支付详情</div>
    </div>
    <div class="order-content bg-white">
      <div class="pay-detail">
        <div class="pay-title">{{ (moneySum*100/100).toFixed(2) }}</div>
        <div class="pay-desc">
          <div class="pay-desc-title">商品名称：</div>
          <div class="pay-desc-info">{{ itemName }}</div>
        </div>
        <div class="pay-desc">
          <div class="pay-desc-title">状态：</div>
          <div class="pay-desc-info">{{ state | payStateName }}</div>
        </div>
        <div class="pay-but" @click="clickBack" v-if="isHasOrder">关闭</div>
        <div class="pay-but" @click="clickPay" v-else>确认支付</div>
      </div>
    </div>
  </div>
</template>

<script>
import {  mapGetters, mapActions } from 'vuex'
import { now } from 'moment';

export default {
  name: 'Wxmp',
    computed: {
    ...mapGetters(['isMobile', 'wxOpenid', 'wxExpiresIn', 'isBrowser', 'staticUrl'])
  },
  data () {
    return {
      order_id: '',
      wx_app_id: '',
      state: 0,// 0：未支付，2：支付成功，发货成功，5：补单成功，7：发货失败
      isHasOrder: false,
      moneySum: '',
      itemName: '',
    }
  },
  created () {
    if (typeof(this.$route.query.order_id) === 'undefined' || this.$route.query.order_id ==='') {
      this.showError('您访问的地址不正确，请确认地址！')
      return
    } else {
      this.order_id = this.$route.query.order_id;
      this.wx_app_id = this.$route.query.wx_app_id;
    }
    if (this.isBrowser !== 'weixin' && !this.isMobile) {
      this.showError('请在微信服务器端打开')
      return
    }
    // if (this.wxOpenid === '' || this.wxExpiresIn < Date.now()) {
    //   this.goWxpayAuth()
    //   return
    // }
    // if (this.wxOpenid !== '' && this.wxExpiresIn > Date.now()) {
    //   this.queryOrder()
    // } else {
    //   this.showError('您不满足条件，请联系我们！')
    //   return
    // }

    this.queryOrder()
  },
  methods: {
    ...mapActions(['QueryBambooOrder','GetBambooCreateZhuziOrder','ZhuziOrderCreateJsapiWxpay','ZhuziWxpayAuth']),
    clickPay () {
      this.queryOrder()
    },
    queryOrder () {
      if(this.order_id.length===0){
        this.showError('订单号不正确')
        return
      }

      this.QueryBambooOrder({bambooApp: true,order_id: this.order_id, app_id: window.bambooAppId, sdk_channel: 'zhuzi'}).then((res) => {
        if (res.code === 1) {
          this.showError(res.msg)
          return
        } else {
          this.state = res.state
          this.isHasOrder = res.is_has_zhuzi_order
          this.moneySum = res.money_sum
          this.itemName = res.item_name
          if (!this.isHasOrder) {
            this.GetCreateZhuziOrderInfo()
          }
        }
      })
    },
    GetCreateZhuziOrderInfo () {
      this.GetBambooCreateZhuziOrder({bambooApp: true,order_id: this.order_id}).then(res => {
        if (res.code === 1) {
          this.showError(res.msg)
          return
        } else {
          this.moneySum = res.money_sum
          this.itemName = res.item

          var zhuziParams = {
            zhuziApp: true,
            app_id: res.app_id,
            channel: res.channel,
            device_type: 'iOS',
            server_id: res.server_id,
            role_id: res.role_id,
            role_name: res.role_name,
            item_id: res.item_id,
            item: res.item,
            num: res.num,
            pay_channel: res.pay_channel,
            plat: res.plat,
            money: res.money,
            cp_order_id: res.cp_order_id,
            custom: res.custom,
            notify_url: res.notify_url,
            user_id: res.user_id,
            sign: res.sign,
            ts: res.ts,
            callback_url: 'https://pay.17youshan.com/stored/zhuziReturn',
          }
          this.zhuziCreateOrder(zhuziParams)
        }
      })
    },
    zhuziCreateOrder (params) {
      if(this.isBrowser === 'weixin'){
        params.wx_openid = this.wxOpenid
        const wxInfo = {
          appId: "",     //公众号ID，由商户传入
          timeStamp: "",         //时间戳，自1970年以来的秒数
          nonceStr: "", //随机串
          package: "",
          signType: "",         //微信签名方式：
          paySign: "" //微信签名
        }
        this.ZhuziOrderCreateJsapiWxpay(params).then((res) => {
          if (res.code === 1) {
            this.showError(res.msg)
          } else {
            wxInfo.appId = res.rsp.appId
            wxInfo.package = res.rsp.package
            wxInfo.nonceStr = res.rsp.nonceStr
            wxInfo.signType = res.rsp.signType
            wxInfo.paySign = res.rsp.paySign
            wxInfo.timeStamp = res.rsp.timeStamp

            if (typeof WeixinJSBridge == "undefined"){
              if( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(wxInfo), false);
              }else if (document.attachEvent){
                document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(wxInfo));
                document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(wxInfo));
              }
            }else{
              this.onBridgeReady(wxInfo)
            }
          }
        })
      } else {
        this.showError('请在微信服务器端打开!')
      }
    },
    goWxpayAuth () {
      const code = this.getUrlParam('code')
      if (code == null || code == '') {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+this.wx_app_id+'&redirect_uri=https%3A%2F%2Fpay.17youshan.com%2Fstored%2F%3FpageChannel%3Dwxmp%26order_id%3D'+this.order_id+'&response_type=code&scope=snsapi_base&state=#wechat_redirect'
      } else {
        let params = {
          zhuzi: true,
          code: code,
          wx_app_id: this.wx_app_id
        }
        this.ZhuziWxpayAuth(params).then((res) => {
          if (res.code === 1) {
            this.showError(res.msg)
            return
          }else {
            this.queryOrder()
          }
        })
      }
    },
    // 编码函数
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)") //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg) //匹配目标参数
      if (r != null) {
        return unescape(r[2])
      } else {
        return null; //返回参数值
      }
    },
    onBridgeReady (wxInfo) {
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": wxInfo.appId,     //公众号ID，由商户传入
        "timeStamp": wxInfo.timeStamp,         //时间戳，自1970年以来的秒数
        "nonceStr": wxInfo.nonceStr, //随机串
        "package": wxInfo.package,
        "signType": wxInfo.signType,         //微信签名方式：
        "paySign": wxInfo.paySign //微信签名
      },
      function(res){
        if(res.err_msg == "get_brand_wcpay_request:ok" ){
          window.location.href = location.href
        }
      });
    },
    clickBack () {
      WeixinJSBridge.call("closeWindow");
    }
  }
}
</script>

<style lang="scss">

</style>
