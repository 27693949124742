import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '@/views/BaseLayout';
import UserLogin from '@/views/mobile/UserLogin';

// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/TestView')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/OrderView')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/OrderDetailView')
  },
  {
    path: '/orderReturn',
    name: 'orderReturn',
    component: () => import('@/views/OrderReturnView')
  },
  {
    path: '/stored',
    name: 'stored',
    component: () => import('@/views/StoredView')
  },
  {
    path: '/stored/MP_verify_nPeWJnrbBTCJOODm.txt',
    name: 'storedMp',
    component: () => import('@/views/StoredMpView')
  },
  {
    path: '/browser',
    name: 'browser',
    component: () => import('@/views/BrowserView')
  },
  {
    path: '/mobileLogin',
    name: 'mobileLogin',
    component: UserLogin
  },
  // {
  //   path: '/stored/zhuzi',
  //   name: 'storedZhuzi',
  //   component: () => import('@/views/StoredZhuziView')
  // },
  {
    path: '/stored/zhuziReturn',
    name: 'storedZhuziReturn',
    component: () => import('@/views/StoredZhuziReturnView')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router;
