import { render, staticRenderFns } from "./BaseLayout.vue?vue&type=template&id=78a08b69"
import script from "./BaseLayout.vue?vue&type=script&lang=js"
export * from "./BaseLayout.vue?vue&type=script&lang=js"
import style0 from "./BaseLayout.vue?vue&type=style&index=0&id=78a08b69&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../17/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports