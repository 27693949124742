import service from '@/utils/services'
import storage from '@/utils/localStorage'
import utils from '@/utils/utils'
// import md5 from 'md5'

const isMobile = utils.isMobile()
const isIPhone = utils.isIPhone()
const isBrowser = utils.getSystemBrowser()
const openToken = utils.getQueryParam('openToken')
const openId = utils.getQueryParam('openId')
const pageChannel = utils.getQueryParam('pageChannel')

const state = {
  wxOpenid: '',
  wxExpiresIn: 0,
  pageChannel: pageChannel,
  openId: openId,
  openToken: openToken,
  isMobile: isMobile,
  isIPhone: isIPhone,
  isBrowser: isBrowser,
  deviceIdSting: '',
  staticUrl: window.staticUrl,
  qqUrl: '//photogz.photo.store.qq.com/psc?/V10cPiTm02W8V4/ruAMsa53pVQWN7FLK88i5mlupnWqHpylFaIsBF35RVklq0gmYuLn73YUrJZP9jJhZdxlP99JC5bIqjGth4N5Yk6IPYJDfue6X3xMutG8oDA!/b=&bo=kgTkCZIE5AkWECA',
  gameUserInfo: {
    channel_user_id: '',
    username: '',
    realname: '',
    mobile: '',
    open_id: '',
    token: '',
    user_id: '',
    zhuziToken: '',
    openToken: ''
  },
  servers: [],
  serverRoleData: [],
  roles: [],
  payState: [
    { id: -1, name: '全部状态' },
    { id: 0, name: '未支付' },
    { id: 1, name: '正在发货' },
    { id: 2, name: '发货成功' },
    { id: 3, name: '支付失败' },
    { id: 4, name: '补单中' },
    { id: 5, name: '补单成功' },
    { id: 6, name: '发货失败' },
    { id: 7, name: '发货失败' }
  ],
  itemsTest: [
    { item_id: 'com.qingniao.voucher.6',	item_name: '6代金券', item_price: 6, item_num: 6, item_discount: 6, img: window.staticUrl+'num2.png' },
    { item_id: 'com.qingniao.voucher.68',	item_name: '68代金券', item_price: 68, item_num: 68, item_discount: 68, img: window.staticUrl+'num1.png'},
    { item_id: 'com.qingniao.voucher.128',	item_name: '128代金券', item_price: 128, item_num: 128, item_discount: 128, img: window.staticUrl+'num2.png' },
    { item_id: 'com.qingniao.voucher.328',	item_name: '328代金券', item_price: 328, item_num: 328, item_discount: 328, img: window.staticUrl+'num3.png' },
    { item_id: 'com.qingniao.voucher.500',	item_name: '500代金券', item_price: 500, item_num: 500, item_discount: 500, img: window.staticUrl+'num4.png'}
  ],
  items: [
    // { item_id: 'com.qingniao.voucher.500',	item_name: '500代金券', item_price: 500, item_num: 500, item_discount: 500, img: window.staticUrl+'num1.png'},
    // { item_id: 'com.qingniao.voucher.1000',	item_name: '1000代金券', item_price: 1000, item_num: 1000, item_discount: 1000, img: window.staticUrl+'num2.png' },
    // { item_id: 'com.qingniao.voucher.2000',	item_name: '2000代金券', item_price: 2000, item_num: 2000, item_discount: 2000, img: window.staticUrl+'num3.png' },
    // { item_id: 'com.qingniao.voucher.3000',	item_name: '3000代金券', item_price: 3000, item_num: 3000, item_discount: 3000, img: window.staticUrl+'num4.png'},
    // { item_id: 'com.qingniao.voucher.5000',	item_name: '5000代金券', item_price: 5000, item_num: 5000, item_discount: 5000, img: window.staticUrl+'num5.png'},
    // { item_id: 'com.qingniao.voucher.10000',	item_name: '10000代金券', item_price: 10000, item_num: 10000, item_discount: 10000, img: window.staticUrl+'num6.png'},
    { item_id: 'com.qingniao.voucher.68',	item_name: '68代金券', item_price: 68, item_num: 68, item_discount: 68, img: window.staticUrl+'num1.png'},
    { item_id: 'com.qingniao.voucher.128',	item_name: '128代金券', item_price: 128, item_num: 128, item_discount: 128, img: window.staticUrl+'num2.png' },
    { item_id: 'com.qingniao.voucher.328',	item_name: '328代金券', item_price: 328, item_num: 328, item_discount: 328, img: window.staticUrl+'num3.png' },
    { item_id: 'com.qingniao.voucher.648',	item_name: '648代金券', item_price: 648, item_num: 648, item_discount: 648, img: window.staticUrl+'num4.png'},
    { item_id: 'com.qingniao.voucher.1298',	item_name: '1298代金券', item_price: 1298, item_num: 1298, item_discount: 1298, img: window.staticUrl+'num5.png'},
    { item_id: 'com.qingniao.voucher.1998',	item_name: '1998代金券', item_price: 1998, item_num: 1998, item_discount: 1998, img: window.staticUrl+'num6.png'},
  ],
  storedData: [
    { id: 1, title: '支付宝', name: 'alipay', image: window.staticUrl+'ali_pay.png', payUrl: window.zhuziApiUrl+'pay/order/create/alipay' },
    { id: 2, title: '微信', name: 'wxpay', image: window.staticUrl+'wx_pay.png', payUrl: window.zhuziApiUrl+'pay/order/create/wxpay'  }
  ]
}

const getters = {
  pageChannel: state => state.pageChannel,
  openId: state => state.openId,
  openToken: state => state.openToken,
  isMobile: state => state.isMobile,
  isIPhone: state => state.isIPhone,
  isBrowser: state => state.isBrowser,
  deviceIdSting: state => {
    if (state.deviceIdSting === '') {
      state.deviceIdSting = storage.get('device_id')
    }
    return state.deviceIdSting
  },
  staticUrl: state => state.staticUrl,
  qqUrl: state => state.qqUrl,
  gameUserInfo: (state) => {
    if (state.gameUserInfo.open_id === '') {
      let gameUserInfo = storage.get('gameUserInfo')
      if (gameUserInfo !== null) {
        state.gameUserInfo = JSON.parse(gameUserInfo)
      }
    }
    return state.gameUserInfo
  },
  payState: state => state.payState,
  servers: state => state.servers,
  serverRoleData: state => state.serverRoleData,
  roles: state => state.roles,
  storedData: state => state.storedData,
  items: state => state.items,
  itemsTest: state => state.itemsTest,
  wxOpenid: (state) => {
    if (state.wxOpenid === '') {
      state.wxOpenid = storage.get('wxOpenid')
    }
    return state.wxOpenid
  },
  wxExpiresIn: (state) => {
    if (state.wxExpiresIn === 0) {
      state.wxExpiresIn = storage.get('wxExpiresIn')
    }
    return state.wxExpiresIn
  }
}

const mutations = {
  updateGameUserInfo (state, params) {
    // Object.assign(state.gameUserInfo, params)
    storage.set('gameUserInfo', JSON.stringify(params))
  },
  updateWxOpenid (state, wxOpenid) {
    storage.set('wxOpenid', wxOpenid)
  },
  updateWxExpiresIn (state, wxExpiresIn) {
    storage.set('wxExpiresIn', wxExpiresIn)
  }
}

const actions = {
  /*
  GetParamsSign ({ commit }, params) {
    return new Promise((resolve, reject) => {
      let payKey = 'test1111'
      let strJoin = ''
      let newParams = {}
      if (JSON.stringify(params) == '{}' || params == null) {
        reject({ code: 1, msg: "参数不正确！" })
      } else {
        let key = Object.keys(params)?.sort()
        for (let i = 0; i < key.length; i++) {
          newParams[key[i]] = params[key[i]]
        }
        for (let key in newParams) {
          strJoin = strJoin + key + "=" + newParams[key] + "&"
        }
        let sign = md5(payKey + strJoin)
        resolve({ code: 0, msg: "OK！", data:{ str: strJoin, sign: sign } })
      }
    })
  },
  */
  ZhuziCodeSend ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/code/send', { zhuzi: true, mobile: params.mobile }).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ZhuziAccountLogin ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/login/account', { zhuzi: true, username: params.account, password: params.password }).then((res) => {
        if (res.data.code === 0) {
          var gameUserInfo = {
            username: res.data.data.username,
            realname: res.data.data.realname,
            mobile: res.data.data.mobile,
            token: res.data.data.token,
            user_id: res.data.data.user_id,
            openToken: state.openToken
          }
          state.gameUserInfo = gameUserInfo
          commit('updateGameUserInfo', gameUserInfo)
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ZhuziMobileLogin ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/login/mobile', { zhuzi: true, mobile: params.mobile, code: params.code }).then((res) => {
        if (res.data.code === 0) {
          var gameUserInfo = {
            username: res.data.data.username,
            realname: res.data.data.realname,
            mobile: res.data.data.mobile,
            token: res.data.data.token,
            user_id: res.data.data.user_id
          }
          state.gameUserInfo = gameUserInfo
          commit('updateGameUserInfo', gameUserInfo)
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  BambooSdkLogin ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/login', { bamboo: true, data: { app_id: window.zhuziAppId,uid: params.user_id, token: params.token } }).then((res) => {
        if (res.data.code === 0) {
          var gameUserInfo = {
            channel_user_id: parseInt(res.data.data.channel_user_id),
            username: params.username,
            realname: params.realname,
            mobile: params.mobile,
            open_id: res.data.data.open_id,
            token: res.data.data.token,
            user_id: res.data.data.user_id,
            zhuziToken: params.token,
            openToken: state.openToken
          }
          state.gameUserInfo = gameUserInfo
          commit('updateGameUserInfo', gameUserInfo)
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ZhuziOrderCreateAlipay ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/pay/order/create/alipay', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ZhuziOrderCreateWxpay ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/pay/order/create/wxpay', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ZhuziOrderCreateJsapiWxpay ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/pay/wxpay/jsapiPay', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ZhuziWxpayAuth ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/pay/wxpay/auth', params).then((res) => {
        if (res.data.code === 0) {
          let expires = Date.now() + 1000*res.data.data.expires_in
          state.wxOpenid = res.data.data.openid
          state.wxExpiresIn = expires
          commit('updateWxOpenid', res.data.data.openid)
          commit('updateWxExpiresIn', expires)
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  GetRechargePreOrder ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/recharge/preOrder', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  GetZhuziOrderList ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/order/list', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  GetRechargeCheck ({ commit }) {
    return new Promise((resolve, reject) => {
      service.post('/recharge/check', {bamboo:true, open_id: state.openId, key: state.openToken}).then((res) => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  GetBambooUserCheck ({ commit }) {
    return new Promise((resolve, reject) => {
      if (state.openId !== state.gameUserInfo.open_id) {
        resolve({code: 1})
      } else {
        service.post('/recharge/check', {bamboo:true, open_id: state.gameUserInfo.open_id, key: state.openToken}).then((res) => {
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      }
    })
  },
  CheckUserZhuziOrder({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/pay/order/query', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 获取账号游戏角色 state.gameUserInfo.token
  GetBambooGameRoles ({ commit }) {
    return new Promise((resolve, reject) => {
      service.post('/recharge/roles', {bamboo:true, token: state.gameUserInfo.token, open_id: state.gameUserInfo.open_id}).then((res) => {
        if (res.data.code === 0) {
          state.serverRoleData = res.data.data.list
          res.data.data.list.forEach(element => {
            state.servers.push({ id: element.server_id, name: element.server_name })
            state.roles.push({ id: element.role_id, name: element.role_name })
          });
        }
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  BambooOrderCreate ({ commit },params) {
    // bamboo=true&user_id=12345&open_id=afwbwebwefawefa&server_id=111&role_id=1&item_id=100&item_name=元宝&item_desc=可以买道具&extra=sfwewf&money=100&coin=100
    return new Promise((resolve, reject) => {
      service.post('/order/create', params).then((res) => {

        if (res.data.code === 0) {
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  GetBambooCreateZhuziOrder ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/order/createZhuziOrder', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  QueryBambooOrder({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/order/query', params).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          resolve(res.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  UserOut ({ commit }) {
    let gameUserInfo = {
      channel_user_id: '',
      username: '',
      realname: '',
      mobile: '',
      open_id: '',
      token: '',
      user_id: '',
      zhuziToken: '',
      openToken: ''
    }
    commit('updateGameUserInfo', gameUserInfo)
    commit('updateWxOpenid', '')
    commit('updateWxExpiresIn', 0)
  },
  UserOutWx ({ commit }) {
    commit('updateWxOpenid', '')
    commit('updateWxExpiresIn', 0)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

