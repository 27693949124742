export default {
  root_path: process.env.VUE_APP_API_ROOT,
  unDataText: '没有數据......',
  submenu: [],
  gameswitch: false,
  ShowPhonePage: 'bind',
  payswitch: false,
  channelswitch: false,
  DiscountSwitch: false,
  DiscountSwitchData: {},
  OpenSubSendDialog: false,
  memPayDialog: false,
  moneyTip: false,
  payShow: true,
  getShow: false,
  TableLoading: true,
};
