<template>
  <div class="dialog_wrap" @click="closeDialog">
    <div class="dialog_wrap_bg" @click.stop="noTouch">
    <div class="dialog_wrap_close" @click="closeDialog"></div>
      <div class="dialog_wrap_content" v-if="loginTpye===1">
        <div class="form-input">
          <span>+86</span>
          <img :src="staticUrl + 'bg_line.png'" class="form-input-line"/>
          <input class="mobile-input" type="tel" @input="inputNumeric"  placeholder="1xxxxxxxxxx(共11位)" v-model.trim="mobileNum" />
        </div>
        <div class="form-input">
          <span><img :src="staticUrl + 'code.png'" class="form-input-img"></span>
          <input class="code-input" type="tel" maxlength="6" placeholder="请输入验证码" v-model.trim="mobileCode" />
          <div class="code-btn" @click="sendCode">
            {{ sendSec>0?sendSec+'秒后再试':'获取验证码' }}
          </div>
        </div>
        <div class="btn-ok" @click="mobileLogin">确定</div>
        <div class="login-type" @click="channgeType">账号登录</div>
      </div>
      <div class="dialog_wrap_content" v-else>
        <div class="form-input">
          <span><img :src="staticUrl + 'user.png'" class="form-input-img"></span>
          <input class="mobile-input" type="account" placeholder="请输入账号" v-model.trim="account" />
        </div>
        <div class="form-input">
          <span><img :src="staticUrl + 'password.png'" class="form-input-img"></span>
          <input class="mobile-input" type="password" placeholder="请输入密码" v-model.trim="password" />
        </div>
        <div class="btn-ok" @click="accountLogin">确定</div>
        <div class="login-type" @click="channgeType">手机号码登录</div>
      </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import md5 from 'md5';

export default {
  name: 'WrapLogin',
  computed: {
    ...mapGetters([
      'userInfo',
      'isMobile',
      'staticUrl'
    ])
  },
  watch: {
    mobileNum (value) {
      if (value.length > 0) {
        this.mobileNum = value.replace(/[^0-9]/, '')
        if (value[0] !== '1') {
          this.mobileNum = ''
        }
        if (value.length > 11) {
          this.mobileNum = value.substr(0, 11)
        }
      }
    }
  },
  data () {
    return {
      mobileNum: '',
      mobileCode: '',
      sendSec: 0,
      loginTpye: 1,
      lockAppoint: false,
      account: '',
      password: ''
    }
  },
  created () {
    document.getElementsByTagName('body')[0].classList.add('noscroll');
  },
  beforeDestroy () {
    document.getElementsByTagName('body')[0].classList.remove('noscroll');
  },
  methods: {
    ...mapActions(['UserOut','ZhuziAccountLogin','ZhuziMobileLogin', 'BambooSdkLogin', 'GetBambooGameRoles','ZhuziCodeSend']),
    noTouch () {

    },
    inputNumeric (e) {
      this.mobileNum = e.target.value.replace(/\D/, '')
    },
    closeDialog () {
      this.$emit('onClose', true)
    },
    channgeType () {
      if (this.loginTpye === 1) {
        this.loginTpye = 2
      } else {
        this.loginTpye = 1
      }
    },
    sendCode () {
      this.mobileNum = this.mobileNum.trim()
      if (this.mobileNum.length !== 11) {
        this.showError('请输入正确的手机号码')
        return;
      }
      if (this.mobileNum.indexOf('0') === 0) {
        this.mobileNum = this.mobileNum.substr(1, this.mobileNum.length - 1)
      }
      if (this.sendSec > 0) {
        return;
      }
      this.sendSec = 60
      let timeId = setInterval(() => {
        this.sendSec--
        if (this.sendSec <= 0) {
          this.sendSec = 0
          clearInterval(timeId)
        }
      }, 1000)
      this.ZhuziCodeSend({ mobile: this.mobileNum }).then(res => {
        this.showSuccess('验证码发送成功')
      }).catch(() => {
        this.showError('验证码发送失败')
      })
    },
    mobileLogin () {
      if (this.mobileNum.length !== 11) {
        this.showError('请输入正确的手机号码')
        return;
      }
      this.mobileCode = this.mobileCode.trim()
      if (this.mobileCode.length !== 6) {
        this.showError('请输入6位的验证码')
        return;
      }
      this.ZhuziMobileLogin({ mobile: this.mobileNum, code: this.mobileCode }).then((res) => {
        if (res.code === 1) {
          this.showError(res.msg)
          return
        } else {
          this.doBambooLogin(res)
        }
      }).catch(() => {
        this.showError('请重确认手机号码及验证码是否正确！')
      })
    },
    accountLogin () {
      if (this.account === '') {
        this.showError('请输入账号，账号不能为空！')
        return
      }
      if (this.password === '') {
        this.showError('请输入密码，密码不能为空！')
        return
      } else {
        if(this.password.length<6) {
          this.showError('密码至少6位，请重新输入！')
          return
        }
      }
      this.ZhuziAccountLogin({ account: this.account, password: md5(this.password) }).then(res => {
        if (res.code === 1) {
          this.showError(res.msg)
          return
        } else {
          this.doBambooLogin(res)
        }
      }).catch(() => {
        this.showError('请重确认账号以及密码是否正确！')
      })
    },
    doBambooLogin (params) {
      this.BambooSdkLogin(params).then((res) => {
        this.getRoles()
        this.showSuccess('登录成功！')
        window.location.href = ('/stored/')
      }).catch(() => {
        this.showError('登录失败')
        this.goOut()
      })
    },
    getRoles () {
      this.GetBambooGameRoles().then((res) => {
        if (res.msg === 'token已过期,请重新登录！') {
          this.goOut()
        }
      })
    },
    goOut() {
      this.UserOut().then((res) => {
        window.location.href = ('/')
      })
    }
  }
}
</script>

<style lang="scss">

</style>
