<template>
    <div id="app" >
      <router-view />
    </div>
  </template>

<script>
export default {
  name: 'BaseLayout',
  data () {
    return {}
  },
  computed: {},
  created () {},
}
</script>

<style lang="scss">
  @import "@/assets/styles/index.scss";
</style>
