<template>
  <div id="login" :class="isMobile?'mobile-login':'login'">
    <form>
      <div class="form_control">
        <input v-model="formData.account" type="account" placeholder="请输入账号" class="form_input_account"/>
        <span class="tip" :hidden="tip_account"><img :src="staticUrl + 'tips.png'" alt>{{ tip_text }}</span>
      </div>
      <div class="form_control">
        <input v-model="formData.password" type="password" autocomplete placeholder="请输入密码" class="form_input_password"/>
        <span class="tip" :hidden="tip_password"><img :src="staticUrl + 'tips.png'" alt>{{ tip_text }}</span>
      </div>
    </form>
    <div class="login-button">
      <button type="button"  @click="loginClick" class="button">登录</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import md5 from 'md5'

export default {
  name: 'userLogin',
  data () {
    return {
      tip_text: '',
      tip_account: true,
      tip_password: true,
      formData: {
        account: '',
        password: ''
      },
    }
  },
  created () {
  },
  computed: {
    ...mapGetters(['isMobile', 'staticUrl'])
  },
  methods: {
    ...mapActions(['ZhuziUserLogin']),
    onRegister () {
      this.$router.push({ name: 'register', query: {} })
    },
    loginClick () {
      if (this.formData.account === '') {
        this.tip_text = '请输入账号，账号不能为空！'
        this.tip_account = false
        return
      } else {
        this.tip_account = true
      }
      if (this.formData.password === '') {
        this.tip_text = '请输入密码'
        this.tip_password = false
        return
      } else {
        if(this.formData.password.length<6) {
          this.tip_text = '密码至少6位，请重新输入'
          this.tip_password = false
          return
        }
        this.tip_password = true
      }
      this.ZhuziUserLogin({ account: this.formData.account, password: md5(this.formData.password) }).then(res => {
        if (typeof(res.code) === 'undefined' && res.code === 0) {
          this.showError(res.msg)
          return
        } else {
          this.$router.push({ name: 'home', query: {} })
        }
      }).catch(() => {
        this.showError('请重确认账号以及密码是否正确！')
      })
    }
  }
}
</script>

<style lang="scss">
  .tip{
    padding-left: 16px;
    font-size:12px;
    font-family:SimSun;
    color:rgba(255,64,64,1);
    line-height:30px;
  }
</style>
